<template>
  <div>
    <b-row>
      <b-col md="3" class="mb-base" v-if="isMainInstance">
        <router-link
          :to="{ name: 'integrations-webhook', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="require('@/assets/images/integrations/webhook.png')"
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">Webhook</h4>
          </b-card>
        </router-link>
      </b-col>
      <b-col md="3" class="mb-base">
        <router-link
          :to="{ name: 'integrations-webhook', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="require('@/assets/images/integrations/1c.png')"
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">1C: Фитнес Клуб</h4>
          </b-card>
        </router-link>
      </b-col>
      <b-col md="3" class="mb-base">
        <router-link
          :to="{ name: 'integrations-email', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="require('@/assets/images/integrations/email.png')"
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">Email</h4>
          </b-card>
        </router-link>
      </b-col>
      <b-col md="3" class="mb-base">
        <router-link
          :to="{ name: 'integrations-google', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="
                  require('@/assets/images/integrations/google-analytics.png')
                "
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">Google</h4>
          </b-card>
        </router-link>
      </b-col>
      <b-col md="3" class="mb-base">
        <router-link
          :to="{ name: 'integrations-yandex', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="
                  require('@/assets/images/integrations/yandex-metrika.png')
                "
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">Yandex Метрика</h4>
          </b-card>
        </router-link>
      </b-col>
      <b-col md="3" class="mb-base" v-if="isMainInstance">
        <router-link
          :to="{ name: 'integrations-facebook', params: { id: 'add' } }"
        >
          <b-card align="center">
            <div class="test">
              <img
                :src="
                  require('@/assets/images/integrations/facebook-pixel.png')
                "
                alt="graphic"
                width="180"
                class="mx-auto"
              />
            </div>
            <h4 class="mb-2">Facebook Pixel</h4>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    isMainInstance() {
      return !process.env.VUE_APP_ENV || process.env.VUE_APP_ENV !== "white";
    },
  },
  metaInfo() {
    return {
      title: "moovee - Список интеграций",
    };
  },
};
</script>

<style lang="scss">
.test {
  padding-top: 55px;
  display: inline-block;
  height: 190px;
  vertical-align: middle;
}
</style>
